//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      order_no: '',
      receiver_phone: '',
      receiver_name: '',
      goods_name: '',
      order_num: {},
      searchForm: {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        from: '',
        payment_code: '',
        order_type: 'whole',
        goods_type: '',
        state_type: 'all',
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    tabClick() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList(1);
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.order_no = '';
      this.receiver_phone = '';
      this.receiver_name = '';
      this.goods_name = '';
      this.searchForm = {
        page: 1,
        rows: 10,
        keywords_type: '',
        keywords: '',
        create_time: [],
        from: '',
        payment_code: '',
        order_type: 'whole',
        goods_type: '',
        state_type: 'all',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getKeywords(val, type) {
      let searchForm = this.searchForm;
      switch (type) {
        case 1:
          this.receiver_phone = '';
          this.receiver_name = '';
          this.goods_name = '';
          searchForm.keywords_type = 'order_no';
          break;
        case 2:
          this.receiver_phone = '';
          this.order_no = '';
          this.goods_name = '';
          searchForm.keywords_type = 'receiver_name';
          break;
        case 3:
          this.order_no = '';
          this.receiver_name = '';
          this.goods_name = '';
          searchForm.keywords_type = 'receiver_phone';
          break;
        case 4:
          this.order_no = '';
          this.receiver_name = '';
          this.receiver_phone = '';
          searchForm.keywords_type = 'goods_name';
          break;
      }
      searchForm.keywords = val;
    },
    getList(is_tab) {
      let searchForm = Object.assign(this.searchForm);
      let data = {
        page: searchForm.page,
        rows: searchForm.rows,
        state_type: searchForm.state_type,
      };
      if (searchForm.keywords) {
        data.keywords = searchForm.keywords;
        data.keywords_type = searchForm.keywords_type;
      }
      if (searchForm.from) {
        data.from = searchForm.from;
      }
      if (searchForm.payment_code) {
        data.payment_code = searchForm.payment_code;
      }
      if (searchForm.goods_type) {
        data.goods_type = searchForm.goods_type;
      }
      if (searchForm.order_type != 'whole') {
        data.order_type = searchForm.order_type;
      }
      if (searchForm.create_time.length) {
        for (let i in searchForm.create_time) {
          searchForm.create_time[i] = searchForm.create_time[i] / 1000;
        }
        data.create_time = searchForm.create_time;
      }
      this.$axios.post(this.$api.samecity.service.orderList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
      if (!is_tab) {
        this.$axios.post(this.$api.samecity.service.orderNum, data).then(res => {
          if (res.code == 0) {
            this.order_num = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
