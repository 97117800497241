//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    total: {
      type: Number,
      default: 100
    },
    page: {
      type: Number,
      default: 1
    },
    pageNum: {
      type: Number,
      default: 10
    },
    style: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pageSize: [5, 10, 15, 20, 50, 100]
    }
  },
  methods: {
    handleSizeChange(val) {
      this.page = val
      this.$emit('updatePageNum', val, 0)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.$emit('updatePageNum', val, 1)
    },
    changeSize() {
      this.pageSize = [48]
    }
  },
  watch: {
    style: {
      handler() {
        console.log(1)
        if (this.style == 1) this.changeSize()
      },
      immediate: true
    }
  }
}
